/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
  top: 10px;
}
.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin-left: 6px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.7s infinite ease-in-out both;
  animation: sk-bouncedelay 1.7s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.64s;
  animation-delay: -0.64s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.22s;
  animation-delay: -0.22s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
