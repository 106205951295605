html {
    height: 100%;
}
@font-face {
  font-family: "AntarcticaBeta-Light";
  src: url('./img/AntarcticaBeta-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "AntarcticaBeta-Light",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  background: #fff;
  background: #fff;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-flags{
  margin-top:20px;
}
.menu-flags-button{
  margin: auto;
  max-width:280px!important;
}

.menu-flags ul{
  margin: auto;
  max-width:280px;
}
