html {
    height: 100%;
}
@font-face {
  font-family: "AntarcticaBeta-Light";
  src: url(/static/media/AntarcticaBeta-Light.259a3d22.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "AntarcticaBeta-Light",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  background: #fff;
  background: #fff;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-flags{
  margin-top:20px;
}
.menu-flags-button{
  margin: auto;
  max-width:280px!important;
}

.menu-flags ul{
  margin: auto;
  max-width:280px;
}

/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
  top: 10px;
}
.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin-left: 6px;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.7s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.64s;
}

.spinner .bounce2 {
  animation-delay: -0.22s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}

